<template>
  <div class="layout-profile">
    <div>
      <img :src="user.headImage" alt="" />
    </div>
    <button class="p-link layout-profile-link" @click="onClick">
      <span class="username">{{ user.userName }}</span>
      <i class="pi pi-fw pi-cog"></i>
    </button>
    <transition name="layout-submenu-wrapper">
      <ul v-show="expanded">
        <li v-if="userMobile">
          <button class="p-link">
            <i class="pi pi-fw pi-user"></i><span>{{ userMobile }}</span>
          </button>
        </li>
        <!-- <li>
          <button class="p-link">
            <i class="pi pi-fw pi-inbox"></i><span>Notifications</span
            ><span class="menuitem-badge">2</span>
          </button>
        </li> -->
        <li>
          <button class="p-link" @click="logout">
            <i class="pi pi-fw pi-power-off"></i><span>退出</span>
          </button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expanded: false,
      user: {
        headImage: "assets/layout/images/profile.png",
        userName: "",
        userMobile: "",
      },
    };
  },
  computed: {
    userMobile() {
      if (this.user.userMobile) {
        return (
          this.user.userMobile.substring(0, 3) +
          "****" +
          this.user.userMobile.substring(7, 11)
        );
      }
      return "";
    },
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    logout() {
        localStorage.removeItem("token");
        this.$router.push({ path: "/login" });
    },
  },
  mounted() {
    const { data } = JSON.parse(localStorage.getItem("token"));
    this.user = data;
  },
};
</script>

<style scoped>
</style>