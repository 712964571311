<template>
  <router-view />
</template>

<script>
import { onBeforeMount } from "@vue/runtime-core";
import { useRouter } from "vue-router";
export default {
  setup() {
    onBeforeMount(() => {
      const token = localStorage.getItem("token");
      if (!token) useRouter().push({ path: "/login" });
    });
  },
};
</script>
